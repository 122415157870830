.navbar.is-fixed-bottom {
  /* background: linear-gradient(90deg, rgba(133, 82, 35, 1) 0%, rgba(204, 174, 79, 1) 25%, rgba(228, 197, 119, 1) 50%, rgba(204, 174, 79, 1) 75%, rgba(133, 82, 35, 1) 100%); */
  background-color: #fff
}

a.disabled {
  pointer-events: none;
}

.container {
  padding: 0.5rem;
  margin-bottom: 80px;
  clear: both;
}

.card .media:not(:last-child) {
  margin-bottom: 0.5rem;
}

.profileCard {
  background: url('/assets/masterCard.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 10px;
}

/* @font-face {
  font-family: "dpoint";
  src: url("/assets/fonts/SukhumvitSet-SemiBold.ttf");
} */

@font-face {
  font-family: "dpoint";
  src: url("/assets/fonts/SukhumvitSet-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "dpoint";
  src: url("/assets/fonts/Montserrat.otf");
  unicode-range: U+30-39;
}

* {
  font-family: 'dpoint';
}

/* override */

@media screen and (min-width: 1024px) {
  .navbar {
    min-height: unset;
  }

  .navbar,
  .navbar-end,
  .navbar-menu,
  .navbar-start {
    align-items: unset;
    display: unset;
  }
}

.gold-border {
  border: 2px solid #C8b386;
}

.navbar {
  height: 80px;
}

.title {
  color: #fFFF;
}

.subtitle {
  color: #C8b386;
}

.ucoin {
  color: #6D6E70;
  font-size: 0.6rem;
  margin-top: -5px;
}

.section-title {
  color: #929497 !important;
}

.gold-1 {
  color: #C8b386 !important;
}

.gold-2 {
  color: #929497 !important;
}

.gold-3 {
  background-color: #363229 !important;
}

.details li:before {
  content: "-";
  text-indent: -5px;
}

section {
  padding: 0.3rem;
}

.buttonLink {
  position: absolute;
  bottom: 5px;
  ;
  left: 0;
  right: 0;
}

html {
  background: url('/assets/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.breadcrumb li+li::before {
  color: #C8b386;
  content: ">";
}

.breadcrumb a {
  align-items: center;
  color: #C8b386;
  display: flex;
  justify-content: center;
  padding: 0 .4em;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #C8b386 !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: .25em solid rgb(199 179 134)
}

.swal2-styled.swal2-confirm {
  background-color: #C8b386;
}

button:focus {
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}